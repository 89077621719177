<template>
  <div>
    <b-card header-tag="header" title="Movimientos" footer-tag="footer">
      <template #header>
        <div class="d-flex justify-content-between">
          <h6 class="mb-0">{{ item.content.poliza.concepto }}</h6>

          <b-button
            variant="danger"
            size="sm"
            class="ml-3"
            @click="deleteFileFromImportPoilza(item)"
          >
            Descartar
          </b-button>
        </div>
      </template>

      <!-- tabla -->
      <b-table
        class="momentosC__table"
        :items="item.content.movimientos"
        :fields="fields"
        small
        responsive
        hover
        striped
        bordered
      >
        <template #cell(cuenta)="data">
          <div class="text-danger" v-b-tooltip.hover :title="data.value.error" v-if="data.value.id===''">{{ data.value.clave }}</div>
          <div class="text-primary" v-b-tooltip.hover :title="data.value.nombre" v-else>{{ data.value.clave }}</div>
        </template>
        <template #cell(fuente_financiamiento)="data">
          <div class="text-danger" v-b-tooltip.hover :title="data.value.error" v-if="data.value.id===''">{{ data.value.clave }}</div>
          <div class="text-primary" v-b-tooltip.hover :title="data.value.nombre" v-else>{{ data.value.clave }}</div>
        </template>
        <template #cell(proyecto)="data">
          <div class="text-danger" v-b-tooltip.hover :title="data.value.error" v-if="data.value.id===''">{{ data.value.clave }}</div>
          <div class="text-primary" v-b-tooltip.hover :title="data.value.nombre" v-else>{{ data.value.clave }}</div>
        </template>
        <template #cell(unidad_administrativa)="data">
          <div class="text-danger" v-b-tooltip.hover :title="data.value.error" v-if="data.value.id===''">{{ data.value.clave }}</div>
          <div class="text-primary" v-b-tooltip.hover :title="data.value.nombre" v-else>{{ data.value.clave }}</div>
        </template>
        <template #cell(partida)="data">
          <div class="text-danger" v-b-tooltip.hover :title="data.value.error" v-if="data.value.id===''">{{ data.value.clave }}</div>
          <div class="text-primary" v-b-tooltip.hover :title="data.value.nombre" v-else>{{ data.value.clave }}</div>
        </template>
        <template #cell(tipo_gasto)="data">
          <div class="text-danger" v-b-tooltip.hover :title="data.value.error" v-if="data.value.id===''">{{ data.value.clave }}</div>
          <div class="text-primary" v-b-tooltip.hover :title="data.value.nombre" v-else>{{ data.value.clave }}</div>
        </template>
        <template #cell(importe)="data">
          {{ $formatNumber(data.value) }}
        </template>
        <template #cell(errores)="row">
          <b-form-checkbox v-model="row.detailsShowing" @change="row.toggleDetails">
            Ver errores
          </b-form-checkbox>
        </template>
        <template #row-details="row">
          <li v-for="error in row.item.errors" v-bind:key="error">
            {{ error }}
          </li>
        </template>
      </b-table>

      <template #footer>
        <div class="d-flex justify-content-between">
          <em>
            Archivo:
            <b-badge variant="light">{{ item.file }}</b-badge>
          </em>

          <div>
            <b-badge variant="primary" class="mr-2">Fecha: {{ item.content.poliza.fecha }}</b-badge>
            <b-badge variant="dark" class="mr-2">Folio: {{ item.content.poliza.folio }}</b-badge>
            <b-badge>{{ item.content.poliza.momento }}</b-badge>
          </div>
        </div>
      </template>
    </b-card>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'

export default {
  name: 'MomentosContablesCard',

  props: {
    item: {
      type: Object,
      default: () => ({}),
      require: true
    }
  },

  data () {
    return {
      fields: [
        { key: 'asiento', label: 'Asiento' },
        { key: 'cuenta', label: 'Cuenta' },
        // { key: 'fuente_financiamiento_id', label: 'F. Financiamiento' },
        { key: 'fuente_financiamiento', label: 'F. Fuente Financiamiento' },
        // { key: 'proyecto_id', label: 'Proyecto' },
        { key: 'proyecto', label: 'Proyecto' },
        // { key: 'unidad_administrativa_id', label: '' },
        { key: 'unidad_administrativa', label: 'Unidad A.' },
        // { key: 'partida_id', label: '' },
        { key: 'partida', label: 'Partida' },
        { key: 'tipo_gasto', label: 'Tipo Gasto' },
        { key: 'concepto', label: 'Concepto' },
        { key: 'importe', label: 'Importe' },
        'errores'
      ]
    }
  },

  methods: {
    ...mapMutations('momentosContablesModule', ['deleteFileFromImportPoilza'])
  }
}
</script>

<style lang="scss" scoped>
.momentosC__table {
  max-height: 450px;
}

table th.text-center,
table td.text-center {
  text-align: center;
}
</style>
